<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-dialog v-model="loading" persistent>
              <v-card class="pa-4">
                <v-progress-circular indeterminate color="red"></v-progress-circular>
                <span class="ml-4" style="font-size: 14px">Please wait...</span>
              </v-card>
            </v-dialog>
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                                                                                              v-show="!bookingDetails.hasOwnProperty('zone')"
                                                                                              height="260px"
                                                                                              class="rounded-t-lg"
                                                                                              type="image"
                                                                                            ></v-skeleton-loader> -->

            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(100%)" :src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.image != '' &&
                bookingDetails.zone.image != null
                ? bookingDetails.zone.image
                : zoneImgURL
                " alt="Parking.com garage">
              <v-overlay absolute v-if="!normalFlow">
                <v-card color="transparent">
                  <v-card-text v-if="bookingDetails.booking.autoP2PDetails.paymentStatus == false" class="white--text">

                    <v-row no-gutters>
                      <v-col cols="12" class="text-center">
                        <v-icon class="material-icons-outlined" color="#f2555c">cancel</v-icon>
                      </v-col>

                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12 " class="text-center">
                        <span style="font-weight: bolder;font-size:20px;z-index: 100 !important;">Payment Failed</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" class="text-center">
                        <span style="font-weight: 400;font-size: 12px;">You're payment couldn't be processed</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="white--text" v-if="normalFlow">
                    <v-row no-gutters>
                      <v-col cols="12" class="text-center mb-1">
                        <v-icon class="material-icons-outlined" color="rgba(62, 198, 165, 1)">check_circle</v-icon>
                      </v-col>

                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12 " class="text-center">
                        <span style="font-weight: 600;font-size:20px;z-index: 100 !important;">Payment Successful</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" class="text-center">
                        <span style="font-weight: 400;font-size: 12px;">Thank you ! Your payment is complete</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-overlay>


              <template v-slot:placeholder>
                <v-row no-gutters class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <div class="thankyou_header white--text" v-if="normalFlow">THANK YOU!</div>
          </div>
          <v-card-title class="pa-1"></v-card-title>
          <v-card-text class="pb-1">
            <v-row no-gutters class="mx-0">
              <v-col class="pa-1">
                <p class="black--text">
                  {{
                    bookingDetails.hasOwnProperty("booking")
                      ? bookingDetails.booking.header
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Monthly'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Monthly</v-btn>
              </v-col>
              <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Employee'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Employee</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0" v-if="bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.booking.vehicleNo != null &&
              bookingDetails.booking.vehicleNo != '' &&
              bookingDetails.zone.isGated == '0'
            ">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">License Plate:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("booking")
                      ? bookingDetails.booking.vehicleNo
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Entered At:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("entrytime")
                      ? formatDateTime(bookingDetails.booking.entrytime)
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header">Exited At:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ isTransactionExtended 
                      ? getODExtendedExitTime
                      : bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty("exittime")
                        ? formatDateTime(bookingDetails.booking.exittime)
                        : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <!-- type test expansion v-expansion-panel -->

            <!-- <v-row no-gutters class="mx-0">
                                                                                              <v-col>
                                                                                                <v-expansion-panels>
                                                                                                  <v-expansion-panel>
                                                                                                    <v-expansion-panel-header>
                                                                                                      <p> <span style="font-weight:bold">VISA *** </span> {{
                                                                                                        bookingDetails.hasOwnProperty("booking") &&
                                                                                                          bookingDetails.booking.hasOwnProperty("cardNo") ? bookingDetails.booking.cardNo :
                                                                                                          ""
                                                                                                      }}</p>
                                                                                                    </v-expansion-panel-header>
                                                                                                    <v-expansion-panel-content>
                                                                                                      If you want to disassociate your card, please click here.
                                                                                                      <v-icon class="material-icons-outlined" color="#e4525c"
                                                                                                        @click="disassociate = true">credit_card_off</v-icon>
                                                                                                    </v-expansion-panel-content>
                                                                                                  </v-expansion-panel>
                                                                                                </v-expansion-panels>
                                                                                              </v-col>
                                                                                            </v-row> -->
            <!-- Type test 1 -->
            <v-container fluid pa-0 v-if="!normalFlow">
              <v-row no-gutters>
                <v-col>
                  <v-card flat class="pa-3 text-center " style="border-radius: 10px; background: rgb(242, 85, 92, 0.3)">
                    <v-row>
                      <!-- total payable value -->
                      <v-col class="text-center">
                        <p class="mb-0 text-left" style="font-size: 12px; font-weight: 400">
                          {{ totalPayableText }}
                        </p>
                        <p style="
                        font-weight: bolder;
                        font-size: 30px;
                        margin-bottom: 4px;
                      " class="mt-3">
                          <span style="font-weight: 300">$ </span>{{ totalPayable }}
                        </p>
                        <p style="
                        font-size: 12px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 17.28px;
                      " class="px-5">
                          Includes Service Fee (${{ serviceFee }}). State and Local taxes
                          (${{ serviceTax }})
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3 ml-2">
                <v-col class="text-left" cols="7">
                  <p class="grey-detail-text" style="margin-bottom: 0">
                    Last Parked At
                  </p>
                  <p class="black-detail-text">{{ lastParkedAt }}</p>
                </v-col>
                <v-col class="pl-8 text-left pb-4" cols="5">
                  <p class="grey-detail-text" style="margin-bottom: 0">Plate</p>
                  <p class="black-detail-text">{{ plate }}</p>
                </v-col>
              </v-row>
              <v-row no-gutters class="ml-2">
                <v-col class="text-left pb-3" cols="7">
                  <p class="grey-detail-text" style="margin-bottom: 0">
                    Parked From
                  </p>
                  <p class="black-detail-text">{{ parkedFrom }}</p>
                </v-col>

                <v-col class="pl-8 text-left pb-4" cols="5">
                  <p class="grey-detail-text" style="margin-bottom: 0">
                    Duration
                  </p>
                  <p class="black-detail-text">{{ duration }}</p>
                </v-col>
              </v-row>
            </v-container>
            <!-- (Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking,'autoP2PDetails') && bookingDetails.booking.autoP2PDetails == null || bookingDetails.booking.autoP2PDetails == undefined || (bookingDetails.booking.autoP2PDetails != null && bookingDetails.booking.autoP2PDetails != undefined && Object.keys(bookingDetails.booking.autoP2PDetails).length <= 0)) -->
            <v-container fluid pa-0 v-if="normalFlow">
              <v-card flat class="ma-0 rounded-lg pb-5" tile max-width="100%">
                <v-card-text class="pb-0">
                  <v-row no-gutters class="pt-2 pb-5" v-if=" Object.hasOwnProperty.call(bookingDetails, 'booking')  && Object.hasOwnProperty.call(bookingDetails.booking, 'rewardCardDetails') &&  checkValidValue(bookingDetails.booking.rewardCardDetails) && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails , 'status') && checkValidValue(bookingDetails.booking.rewardCardDetails.status) && bookingDetails.booking.rewardCardDetails.status!='REMOVED' && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'isRentalCar') && checkValidValue(bookingDetails.booking.rewardCardDetails.isRentalCar) && bookingDetails.booking.rewardCardDetails.isRentalCar==1  ">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col class="text-left">
                          <span class="text-left pt-2 font-weight-bold black--text pl-2">
                            Vehicle Details
                          </span>
                        </v-col>
                        <v-col class="text-right ">
                          <v-btn class="text-right align-self-center" text color="#f2555c" style="text-transform: none" @click="openUpdateVehicleDetails()">Edit</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-0">
                        <v-card-text>
                          <v-row no-gutters class="pt-2 pb-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text" style="font-weight: 500;">Plate</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'plateOriginal') && this.checkValidValue(bookingDetails.booking.rewardCardDetails.plateOriginal) && bookingDetails.booking.rewardCardDetails.plateOriginal ?   bookingDetails.booking.rewardCardDetails.plateOriginal : "-"}}</span>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row no-gutters class="pt-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text" style="font-weight: 500;" >Return Date</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ computedRentalReturnDate}}</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="text-left pt-0" no-gutters>
                    <v-col cols="12" class="pa-0" v-if="(Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking , 'rewardCardDetails') && checkValidValue(bookingDetails.booking.rewardCardDetails) && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails , 'status') && checkValidValue(bookingDetails.booking.rewardCardDetails.status) && bookingDetails.booking.rewardCardDetails.status!='REMOVED') || Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'saveCard') && bookingDetails.user.saveCard==1">
                      <p style="
                              font-size: 15px;
                              font-weight: bolder;
                            ">
                        Your Saved Cards
                      </p>
                    </v-col>
                    <v-col
                      v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentType') && Object.hasOwnProperty.call(bookingDetails.booking, 'cardNo') && Object.hasOwnProperty.call(bookingDetails.booking, 'cardOnfile') && bookingDetails.booking.paymentType != '' && bookingDetails.booking.paymentType != null && bookingDetails.booking.cardOnfile != '0' && bookingDetails.booking.cardOnfile !== '2' || (Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentType') && bookingDetails.booking.paymentType == 'Card' && (bookingDetails.booking.cardNo != '' && bookingDetails.booking.cardNo != undefined && bookingDetails.booking.cardNo != null))"
                      cols="12" class="pb-4">
                      <v-row no-gutters class=" pt-2 pb-4" v-if="bookingDetails.booking.paymentType!='APPLE_PAY' && bookingDetails.booking.paymentType!='GOOGLE_PAY'">
                        <v-col cols="8" class="text-left align-self-center" >
                          <p style="
                              font-size: 15px;
                              margin-bottom: 0% !important
                            " class="black--text">
                            Credit Card
                          </p>
                        </v-col>
                        <v-col cols="4" class="text-right" v-if="Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'saveCard') && bookingDetails.user.saveCard==1">

                          <v-btn text color="#f2555c" class="align-self-center" style="text-transform: none"
                          @click="disassociate = true">Remove ></v-btn>
                        </v-col>
                      </v-row>
                      <v-card class="pa-0">
                        <v-card-text>
                          <v-row no-gutters>
                            <v-col cols="1" class="pr-2">
                              <v-icon class="material-icons-outlined" color="#f2555c">
                                credit_card
                              </v-icon>
                            </v-col>
                            <v-col class="text-left pl-2">
                              <p style="font-weight: bold" class="ma-0 pa-0"
                                v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentType')">
                                {{ getPaymentType(bookingDetails.booking.paymentType) }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking , 'rewardCardDetails') && checkValidValue(bookingDetails.booking.rewardCardDetails) && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails , 'status') && bookingDetails.booking.rewardCardDetails.status!='REMOVED' ">
                      <v-row no-gutters>
                        <v-col cols="8">
                          <p style="
                              font-size: 15px;
                              margin-bottom: 0% !important
                            " class="black--text">
                            Reward Card
                          </p>
                        </v-col>
                        <v-col cols="4">

                          <v-btn text color="#f2555c" class="align-self-center pb-4" style="text-transform: none"
                            @click="confirmRewardsDialog = true">Remove ></v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" class="text-center" v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking , 'rewardCardDetails') && checkValidValue(bookingDetails.booking.rewardCardDetails) && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails , 'status') && bookingDetails.booking.rewardCardDetails.status!='REMOVED' ">
                      <v-card style="border-radius: 13px">
                        <v-card-text class="pa-0">
                          <v-img style="border-radius: 13px" v-if="
                            Object.hasOwnProperty.call(
                              bookingDetails,
                              'zone'
                            ) &&
                            Object.hasOwnProperty.call(
                              bookingDetails.zone,
                              'loyaltyFlags'
                            ) &&
                            Object.hasOwnProperty.call(
                              bookingDetails.zone.loyaltyFlags,
                              'logo_path'
                            )
                          " :src="logoPath" class="pa-0" max-width="100%" height="79">
                            <v-row no-gutters
                              style="position: absolute;bottom: 0;background-color: rgb(0, 0, 0 , 0.6);width: 100%;">
                              <v-col class="text-left white--text pl-2" cols="7" style="z-index:100 !important">

                                {{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails,
                                  'rewardCardNumber') ?
                                  bookingDetails.booking.rewardCardDetails.rewardCardNumber : "-" }}
                              </v-col>
                              <v-col class="pr-3" cols="5">
                                <v-chip v-if="bookingDetails.booking.rewardCardDetails.status == 'EXPIRED'" x-small
                                  color="red" class="white--text"> <v-icon color="white"
                                    class="material-icons-outlined pr-1" x-small>error</v-icon> NOT
                                  APPLICABLE</v-chip>
                              </v-col>
                            </v-row></v-img>
                          <v-img v-else src="../assets/newGoldenNugget.jpg" class max-width="230" height="79"></v-img>
                        </v-card-text>
                      </v-card>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row no-gutters>
                <v-col cols="12" class="px-3 text-center" style="font-size: 12px">
                  <v-row no-gutters class="pt-2">
                    <v-col cols="4" class="text-left">
                      <p style="font-size: small" class="black--text mb-2">Parking<span v-if="isTransactionExtended">**</span></p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="text-left">
                      <p style="font-size: small" class="black--text text-right mb-2">
                        {{ "$ " }}
                        {{isTransactionExtended ? getODExtendedTotalRate :
                          bookingDetails.hasOwnProperty("booking") &&
                            bookingDetails.booking.hasOwnProperty("quotedRegularPrice")
                            && bookingDetails.booking.hasOwnProperty("totalPrice")
                            ?
                             (bookingDetails.booking.quotedRegularPrice == null ?
                              Number(bookingDetails.booking.totalPrice).toFixed(2) :
                              Number(bookingDetails.booking.quotedRegularPrice).toFixed(2))
                            : "-"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-0">
                    <v-col cols="4" class="text-left">
                      <v-row no-gutters>
                        <v-col cols="11">
                          <p style="font-size: small" class="black--text mb-2">
                            Service Fee
                          </p>
                        </v-col>
                        <v-col cols="1">
                          <v-tooltip bottom v-model="show">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                                @click="show = !show" v-on="on">info</v-icon>
                            </template>
                            <span>This fee helps cover the use of the technology that provides a better parking
                              experience.</span>
                          </v-tooltip>
                        </v-col>

                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="text-left">
                      <p style="font-size: small" class="black--text text-right mb-2">
                        {{  
                          isTransactionExtended ? getODExtendedTotalServiceTax :
                            bookingDetails.hasOwnProperty("booking") &&
                            bookingDetails.booking.hasOwnProperty("totalFee")
                            ?
                            "$ " + Number(bookingDetails.booking.totalFee).toFixed(2)
                            : "-"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters
                    v-if="showDiscount">
                    <v-col cols="4" class="text-left">
                      <p style="font-size: small" class="black--text mb-2">
                        Discount
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="text-left">
                      <p style="font-size: small" class="black--text text-right mb-2">
                        {{ getDiscountValue }}
                      </p>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row no-gutters class="mt-2">
                    <v-col cols="6" class="text-left">
                      <p style="font-weight: bold; color: #f2555c; font-size: 15px">
                        Purchase Total <sup>*</sup>
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="text-left">
                      <p style="
                              font-size: small;
                              font-weight: bold;
                              color: #f2555c;
                           " class="text-right">
                        {{ isTransactionExtended ? getODExtendedTotalPrice :
                          bookingDetails.hasOwnProperty("booking") &&
                            bookingDetails.booking.hasOwnProperty("amount") &&
                            bookingDetails.booking.hasOwnProperty("totalFee") &&
                            bookingDetails.booking.hasOwnProperty("totalTax")
                            ? "$ " +
                            Number(bookingDetails.booking.amount).toFixed(2)
                            : "-"
                        }}
                      </p>
                    </v-col>
                    <p class="mb-1">
                      *Includes state and local taxes of
                      {{
                        bookingDetails.hasOwnProperty("booking") &&
                          bookingDetails.booking.hasOwnProperty("totalTax")
                          ? "$ " +
                          Number(bookingDetails.booking.totalTax).toFixed(2)
                          : ""
                      }}
                    </p>
                    <p class="mb-1" v-if="isTransactionExtended">
                      **Includes extended parking fee of
                      {{
                        bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty("OD_Extensions") && 
                        bookingDetails.booking.OD_Extensions.hasOwnProperty("extensionSummary") &&
                        bookingDetails.booking.OD_Extensions.extensionSummary.hasOwnProperty("rate")
                        ? "$ " + bookingDetails.booking.OD_Extensions.extensionSummary.rate
                        : ""
                      }}
                    </p>
                  </v-row>
                  <v-row no-gutters class="px-3">
                    <v-col class="text-left"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters
                v-if="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('couponToRedeemFreeParking') && !(bookingDetails.booking.hasOwnProperty('TypeOfBooking') && (bookingDetails.booking.TypeOfBooking.toLowerCase() == 'ondemand' || bookingDetails.booking.TypeOfBooking == 'Self reservation')) && bookingDetails.booking.couponToRedeemFreeParking != null && bookingDetails.booking.couponToRedeemFreeParking != ''">
                <v-card class="pa-0">
                  <v-card-text class="mb-0 pb-0">
                    <p class="black--text mb-2 text-center" style="font-size: 13px;">Copy this validation code to
                      transfer
                      your parking to a nearby casino until {{ freeCouponValidTill }}</p>
                    <v-row no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-card class="pa-0" flat
                          style="border:1px solid black; display: flex; justify-content: space-between;">
                          <span class="pl-2 my-1">{{ bookingDetails.booking.couponToRedeemFreeParking }}</span>
                          <v-icon color="primary" class="mr-2"
                            @click="copyValidationCode(bookingDetails.booking.couponToRedeemFreeParking)"
                            small>mdi-content-copy</v-icon>
                        </v-card>
                      </v-col>
                    </v-row>
                    <p class="text-center my-1">-OR-</p>
                    <p style="font-size: 11px;" class="mb-0 ml-2">If you scanned your credit card to enter, scan the QR
                      code
                      at exit.</p>
                    <div class="text-center ma-0">
                      <VueQrcode :value="bookingDetails.booking.couponToRedeemFreeParking"></VueQrcode>
                    </div>
                    <p v-if="((bookingDetails.booking.couponToRedeemFreeParkingState != null && bookingDetails.booking.couponToRedeemFreeParkingState != '' && bookingDetails.booking.couponToRedeemFreeParkingState != 'new') || (couponExpired == true))"
                      style="font-size: 13px;" class="black--text mb-0 ml-2 pb-2">This pass has been used or expired and
                      can
                      no longer be used to enter a participating parking facility
                    </p>
                  </v-card-text>
                </v-card>
              </v-row>
              <!-- Type test 2 -->
              <!-- <v-row no-gutters
                                                                                              v-if="bookingDetails.hasOwnProperty('booking') && bookingDetails.booking.hasOwnProperty('guestSaveCard') && bookingDetails.booking.guestSaveCard === '1'">
                                                                                              <v-col class=" text-center">
                                                                                                <p></p>
                                                                                              </v-col></v-row> -->
              <v-row no-gutters v-if="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.state != '11'
              ">
                <v-col cols="12" class="justify-center text-center mt-2 mb-1 amount-content-position" v-if="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('validated') &&
                  bookingDetails.booking.validated == '1'
                ">
                  <v-btn class="elevation-0 white--text" tile small color="green darken-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated == '1'
                    ">VALIDATED</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4" v-else-if="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.state == '11'
              ">
                <v-col cols="12" class="text-center mb-4">
                  <v-btn class="elevation-0 white--text" tile small color="green darken-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated == '1'
                    ">VALIDATED</v-btn>
                </v-col>
                <v-col cols="12" class="text-left">
                  <span class="booking_date">{{
                    bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("Errormessage")
                      ? bookingDetails.booking.Errormessage
                      : ""
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="text-center justify-center mt-1 pt-0 pb-6 mx-2" v-if="bookingDetails.hasOwnProperty('booking') &&
            !(bookingDetails.booking.state == '11') && normalFlow">
            <v-container pa-0 fluid>
              <v-row no-gutters v-show="bookingDetails.hasOwnProperty('booking') &&
                !(bookingDetails.booking.type == '7')
                ">
                <v-col cols="12" v-if="isPreAuthEnabled">
                  <pre-auth-sheet
                    style="border: 1px solid #FFEBEB;border-radius: 8px;font-size: 0.2px; "></pre-auth-sheet>
                </v-col>

                <v-col cols="12">
                  <!-- <v-btn
                                                                                                    rounded
                                                                                                    elevation="0"
                                                                                                    class="receipt_btn white--text"
                                                                                                    @click="navigateToReceipt"
                                                                                                    >Receipt</v-btn> -->
                  <a class="text-caption" @click="navigateToReceipt()">View Receipt</a>
                </v-col>
                <v-col cols="12" class="mt-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('accountType') &&
                  (bookingDetails.booking.accountType == '0' ||
                    bookingDetails.booking.accountType == '1')
                  ">
                  <v-btn rounded elevation="20" class="receipt_btn white--text"
                    @click="navigateToParkingSignUpPage()">Create
                    Account</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4" v-if="bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.hasOwnProperty('mySpotSettings') &&
                bookingDetails.zone.mySpotSettings != null &&
                bookingDetails.zone.mySpotSettings.hasOwnProperty(
                  'parkingDisclosures'
                ) &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures !=
                null &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures != ''
              ">
                <v-col cols="12" class="pt-2">
                  <div class="alert-msg-box">
                    <v-row no-gutters>
                      <v-col cols="12" class="text-start">
                        <p class="pa-0 ma-0" style="
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 501;
                          ">
                          ALERT MESSAGE
                        </p>
                      </v-col>
                    </v-row>
                    <p class="mt-2" v-html="bookingDetails.zone.mySpotSettings.parkingDisclosures
                      " style="
                        font-size: 12px;
                        text-align: justify;
                        letter-spacing: normal;
                        line-height: 1.3;
                      "></p>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.type == '7' &&
                checkReservationEndDate()
              ">
                <v-col cols="12">
                  <v-btn rounded elevation="20" class="receipt_btn white--text" @click="reEnter()">Re-Enter</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <!-- <div>Close</div> -->
            <!-- <v-btn color="deep-purple lighten-2" text>ADD CREDIT CARD</v-btn> -->
          </v-card-actions>
          <v-card-actions class="mx-3 pb-10 pt-10 mt-3 mb-3" v-if="!normalFlow">
            <v-btn width="100%" v-if="!normalFlow && isAutoP2pActive" style="
                  font-weight: 500;
                  background-color: #f2555c !important;
                  font-size: 16px !important;
                  letter-spacing: normal !important;
                  height: 37px !important;
                " elevation="20" rounded class="white--text no-upper-case" @click="navigateToURL()">
              Pay Now</v-btn>
            <v-btn v-if="!isAutoP2pActive" width="100%" text color="#f2555c" style="text-transform: none !important;"
              class=" no-upper-case" @click="navigateToReceipt()">view receipt</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="disassociate" max-width="fit-content">
      <v-card>
        <v-card-title>Unlink My Credit Card</v-card-title>
        <v-card-text>
          Are you sure you want to unlink your card from this license plate number? </v-card-text>
        <v-card-actions>
          <v-row no-gutters class="text-right">
            <v-col><v-btn elevation="0" @click="disassociate = false">Cancel</v-btn>
              <v-btn text color="#e4525c" :loading="disassociateCardBtnLoading" elevation="0"
                @click="disassociateCard">Yes</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errDialog" max-width="fit-content">
      <v-card class="pa-0">
        <v-card-title class="justify-center">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">
            {{ errMsg }}
          </span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="errDialog = false">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="80%" persistent>
      <v-card class="pa-0">
        <v-card-text class="text-center">
          <v-container fluid pa-2>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img alt="Success" contain height="175" src="../assets/success_check.gif" />
                <span style="font-size: 20px">
                  {{ successMsg }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="closeSuccessDialog">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateRewardCardFlag" persistent>
      <v-card>
        <v-card-text>
          <v-row no-gutters class="text-center">
            <v-col cols="12" class="pt-4 ">
              <span class="font-weight-bold black--text">Update Vehicle Details </span>
            </v-col>
            <v-col cols="12" class="pt-2 px-10">
              <span>Please update the return date and time of your rental.</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1" class="text-left align-self-center py-5">
              <v-icon class="material-icons-outlined">calendar_month</v-icon>
            </v-col>
            <v-col cols="11" class="px-2">
              <v-text-field v-model="computedRewardsDateFormatted" @click="() => {
                openRewardsDatePicker = true;
              }
                " hide-details="auto" readonly></v-text-field>
              <v-dialog v-model="openRewardsDatePicker" max-width="450px">
                <v-card style="background-color: #f2555c; color: white"><v-card-title>
                    <v-row no-gutters>
                      <v-col class="text-left" cols="12">
                        <span style="font-size: 15px; color: #fabbbe">{{
                          rewardsTime !== null && rewardsTime !== "" ? formattedRewardsYear : ""
                        }}</span>
                      </v-col>
                      <v-col>
                        <span style="font-size: 30px">
                          {{
                            rewardsTime !== null && rewardsTime !== ""
                              ? formattedRewardsDateTime
                              : ""
                          }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title></v-card>
                <Calendar inline class="text-left" value :stepMinute="15" hourFormat="12" :minDate="minDate"
                  v-model="rewardsTime" @date-select="rewardsTimeChange()" />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row no-gutters >
            <v-col cols="1" class="text-right align-self-center">
              <v-icon class="material-icons-outlined">schedule</v-icon>
            </v-col>
            <v-col cols="11" class="px-3">
              <v-row>
                <v-col>
                  <v-text-field v-model="formattedRewardsTime" @click="() => {
                    openRewardsTimePicker = true;
                    round(rewardsTime);
                  }
                    " readonly></v-text-field>
                  <v-dialog v-model="openRewardsTimePicker" max-width="320px" class="pa-0" @click:outside="() => {
                    setRewardsDefaultDate();
                  }
                    ">
                    <v-card><v-card-title style="background-color: #f2555c; color: white">Return time of your rental
                        car</v-card-title>
                      <Calendar inline show-time time-only class="text-left" value :stepMinute="15"
                        selection-mode="single" hourFormat="12" :minDate="minDate" v-model="rewardsTime" />
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12">
                  <v-btn  rounded block elevation="20"  class="white--text exit_dialog_btn" @click="updateRewardCard()" style="text-transform: none">Update</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2 text-center pt-5">
                  <p style="cursor: pointer" @click="updateRewardCardFlag = false">
                    Close
                  </p>
                </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRewardsDialog">
      <v-card>
        <v-card-title>
          Remove Saved Reward Card
        </v-card-title>
        <v-card-text>
          This action will remove the validation associated with your reward card for this transaction.
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col class="text-right">
              <v-btn text style="text-transform: none" @click="confirmRewardsDialog = false">Close</v-btn>

              <v-btn text style="text-transform: none;" color="#f2555c" @click="removeSavedRewardCard()">
                Remove</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import APIHelper from "../apiHelper";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import bookingsvcAPIHelper from "../bookingsvcAPIHelper";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import * as copy from "@/utils/copyToClipboard";
import moment from 'moment'
import { differenceInMinutes } from "date-fns";
import { EventBus } from "@/lib/EventBus";
import { format, isValid } from "date-fns";
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import { dateToTimeZoneDate, secondsToDHms } from "@/utils/formatDateTime";
// import { getTime } from "date-fns";
// import axios from "axios";
// import loggerHelper from "../loggerHelper";
import Calendar from "../uiComponents/Calendar/Calendar";
import { mapGetters } from "vuex";
export default {
  name: "ThankYou",
  components: {
    VueQrcode,
    PreAuthSheet,
    Calendar
  },
  data: () => ({
    updateRewardCardFlag: false,
    minDate: null,
    rewardsTime: "",
    openRewardsDatePicker: false,
    openRewardsTimePicker: false,
    show: false,
    confirmRewardsDialog: false,
    loading: false,
    pageHeight: window.outerHeight,
    disassociate: false,
    cardRemoved: false,
    successMsg: "",
    successDialog: false,
    errDialog: false,
    errMsg: "",
    disassociateCardBtnLoading: false,
    validationCode: '',
    freeCouponValidTill: '',
    couponExpired: false
  }),
  computed: {
    logoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.logo_path
        : "../assets/newGoldenNugget.jpg";
    },
    formattedRewardsDateTime() {
      return this.rewardsTime !== null && this.rewardsTime !== ""
        ? format(this.rewardsTime, "iii, LLL dd")
        : "";
    },
    formattedRewardsYear() {
      return this.rewardsTime !== null && this.rewardsTime !== ""
        ? format(this.rewardsTime, "yyyy")
        : "";
    },
    formattedRewardsTime() {
      return this.rewardsTime ? format(this.rewardsTime, "hh:mm aa") : "";
    },

    computedRewardsDateFormatted() {
      return this.rewardsTime !== null && this.rewardsTime !== "" && this.rewardsTime !== undefined
        ? format(this.rewardsTime, "dd MMM")
        : new Date();
    },
    computedRentalReturnDate(){
      var dateString = "-"
      if(Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails.booking , 'rewardCardDetails') && Object.hasOwnProperty.call(this.bookingDetails.booking.rewardCardDetails, 'rentalCarValidTill')){
      let datevalue = this.bookingDetails.booking.rewardCardDetails.rentalCarValidTill
        dateString = datevalue.toString().split("T")[0] +" "+ datevalue.toString().split("T")[1].split(".")[0]
      }
      console.log(dateString)
      return this.checkValidValue(dateString) && dateString !="-" ?   format(new Date(dateString) , "dd MMM hh:mm aa") : "-"
    },
    dialogLogoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
        null &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
        undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path
        : "../assets/newGoldenNugget.jpg";
    },
    totalPayableText() {
      return this.bookingDetails?.booking?.autoP2PDetails?.paymentStatus == false ? 'Total Payable Amount' : 'Amount Paid'
    },

    isPreAuthEnabled() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails?.zone, 'preAuth') && Object.hasOwnProperty.call(this.bookingDetails?.zone?.preAuth, 'enabled') && this.bookingDetails?.zone?.preAuth?.enabled == '1' && (this.bookingDetails?.booking?.TypeOfBooking == 'Guest' || this.bookingDetails?.booking?.TypeOfBooking == 'Transient' || this.bookingDetails?.booking?.TypeOfBooking == 'Self reservation' || (this.bookingDetails?.booking?.TypeOfBooking == 'Ondemand' && this.bookingDetails?.zone?.ODChargeUpfront == '0')) && this.bookingDetails?.booking?.eventModeTxn == '0' && this.bookingDetails?.booking?.entryMode != 'CC' && this.bookingDetails?.booking?.payment_status != 'FAILED'
    },
    normalFlow() {
      return (Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails.booking, 'autoP2PDetails') && this.bookingDetails?.booking?.autoP2PDetails != null && this.bookingDetails?.booking?.autoP2PDetails != undefined && Object.hasOwnProperty.call(this.bookingDetails.booking.autoP2PDetails, 'paymentStatus') && this.bookingDetails?.booking?.autoP2PDetails != null && this.bookingDetails?.booking?.autoP2PDetails?.paymentStatus != undefined && this.bookingDetails?.booking?.autoP2PDetails?.paymentStatus == false) ? false : true
    },
    isAutoP2pActive() {
      return (Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails.booking, 'autoP2PDetails') && this.bookingDetails?.booking?.autoP2PDetails != null && this.bookingDetails?.booking?.autoP2PDetails != undefined && Object.hasOwnProperty.call(this.bookingDetails.booking.autoP2PDetails, 'status') && this.bookingDetails?.booking?.autoP2PDetails != null && this.bookingDetails?.booking?.autoP2PDetails?.status != undefined && this.bookingDetails?.booking?.autoP2PDetails?.status == "ACTIVE") ? true : false
    },
    isTransactionExtended() {
      return this.bookingDetails?.booking?.OD_Extensions?.extensions.length ? this.bookingDetails.booking.OD_Extensions.extensions.length > 1 : false;
    },
    getODExtendedExitTime() {
      let exitTime = this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.exitTime ? this.bookingDetails.booking.OD_Extensions.overAllDetails.exitTime : ""
      return format(new Date(exitTime), "MMM dd, hh:mm aa")
    },
    getODExtendedTotalRate() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails?.booking, 'OD_Extensions') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions, 'overAllDetails') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails, 'quotedRegularPrice') ? this.bookingDetails.booking.OD_Extensions.overAllDetails.quotedRegularPrice : ""
    },
    getODExtendedTotalPrice() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails?.booking, 'OD_Extensions') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions, 'overAllDetails') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails, 'price') ? "$ " + this.bookingDetails.booking.OD_Extensions.overAllDetails.price : ""
    },
    getODExtendedTotalServiceTax() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails?.booking, 'OD_Extensions') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions, 'overAllDetails') && Object.hasOwnProperty.call(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails, 'totalFee') ? "$ " + this.bookingDetails.booking.OD_Extensions.overAllDetails.totalFee : ""
    },
    plate() {
      return this.outStandingBookingDetails?.booking?.vehicleNo || "-";
    },
    totalPayable() {
      return Object.hasOwnProperty.call(
        this.outStandingBookingDetails,
        "booking"
      ) &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking,
          "estimatedTotal"
        ) &&
        this.outStandingBookingDetails?.booking?.estimatedTotal != null &&
        this.outStandingBookingDetails?.booking?.estimatedTotal
        ? this.outStandingBookingDetails.booking.estimatedTotal
        : "-";
    },
    lastParkedAt() {
      return this.outStandingBookingDetails?.zone?.zoneName || "-";
    },
    parkedFrom() {

      return this.outStandingBookingDetails?.booking?.entrytime
        ?
        moment.unix(this.outStandingBookingDetails?.booking?.entrytime).utc().format("MMM Do , hh:mm A ")
        : "-";

    },
    duration() {
      let durationInMin = this.outStandingBookingDetails?.booking?.entrytime &&
        this.outStandingBookingDetails?.booking?.exittime
        ? differenceInMinutes(
          new Date(moment.unix(this.outStandingBookingDetails?.booking?.exittime).utc()),
          new Date(moment.unix(this.outStandingBookingDetails?.booking?.entrytime).utc())
        )
        : "-";
      let durationText = durationInMin != '-' ? secondsToDHms(durationInMin * 60) : "-"
      return durationText;
    },
    serviceFee() {
      return this.outStandingBookingDetails?.booking?.estimatedFee || '-'
    },
    serviceTax() {
      return this.outStandingBookingDetails?.booking?.estimatedTax || '-'
    },
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
      zoneDetails: "getterZoneDetails",
      outStandingBookingDetails: "getterOutStandingBookingDetails",
    }),
    cardDisassociated() {
      return this.bookingDetails?.user?.saveCard &&
        this.bookingDetails?.user?.saveCard == "1"
        ? true
        : false;
    },
    showDiscount() {
      return this.isTransactionExtended 
            ? this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.validatedAmount &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != null &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != "" &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != "-"
            : this.bookingDetails?.booking?.validatedAmount && 
              this.bookingDetails.booking.validatedAmount != null &&
              this.bookingDetails.booking.validatedAmount != "" &&
              this.bookingDetails.booking.validatedAmount != "-";
    },
    getDiscountValue() {
      return this.isTransactionExtended 
            ? this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.validatedAmount &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != null &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != "" &&
              this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount != "-"
                ? "-$ " + Number(this.bookingDetails.booking.OD_Extensions.overAllDetails.validatedAmount).toFixed(2)
                : "-"
            : this.bookingDetails?.booking?.validatedAmount && 
              this.bookingDetails.booking.validatedAmount != null &&
              this.bookingDetails.booking.validatedAmount != "" &&
              this.bookingDetails.booking.validatedAmount != "-"
                ? "-$ " +  Number(this.bookingDetails.booking.validatedAmount).toFixed(2)
                : "-"
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.bookingDetails?.booking?.autoP2PDetails) {
        vm.getAutoP2PDetails(vm.bookingDetails?.booking?.autoP2PDetails?.bid)

      }
      if (vm.bookingDetails?.booking) { vm.setFreeBookingDetails(); }
      vm.$vuetify.goTo(0);
      // if(vm.bookingDetails?.booking){vm.setFreeBookingDetails();}
      if (vm.bookingId != null) {
        try {
          var bookingDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + vm.bookingId
          );
          vm.$store.commit(
            "SET_BOOKING_DETAILS",
            bookingDetails.data?.data ? bookingDetails.data.data : null
          );

        } catch (error) {
          console.log(error);
        }
      }
    });
  },
  mounted() {
    EventBus.$on("SET_FREE_BOOKING", () => {
      this.setFreeBookingDetails();
    });

    EventBus.$on("SET_TIME", () => {
      this.setRewardsTime();
    });
    EventBus.$on('AUTO_P2P_BID', (bookingDetails) => {
      this.loading = true;
      this.getAutoP2PDetails(bookingDetails?.booking?.autoP2PDetails?.bid)
      this.loading = false;
    })
    localStorage.removeItem("bookingDetails");
  },
  methods: {
    setRewardsTime(){
      this.openRewardsTimePicker = false
    },
    openUpdateVehicleDetails(){
      this.setRewardsDefaultDate();
      this.updateRewardCardFlag = true;
    },
    async updateRewardCard(){
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var result = await bookingsvcAPIHelper(
          "POST",
          "/api/v1/updateRewardCardDetails",
          { bid: this.bookingDetails?.booking?.bid  , rentalCarValidTill: format(this.rewardsTime, 'yyyy-MM-dd hh:mm:ss')}
        );
        if (result?.data?.status) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          await this.getBookingDetails()
          this.confirmRewardsDialog = false;
          this.updateRewardCardFlag = false;
          this.alertMsg =
            result?.data?.message || "Error in updating card";
          this.successDialog = true;

        }
      }
      catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.confirmRewardsDialog = false

        console.log(error)

      }
    },
    round(time) {
      let exitdate =
        time !== null && time !== "" ? format(time, "yyyy/MM/dd") : new Date();
      let selectedMins =
        time !== null && time !== "" ? format(time, "mm") : new Date();
      let selectedHours =
        time !== null && time !== "" ? format(time, "hh") : new Date();
      let selectedAMPM =
        time !== null && time !== "" ? format(time, "a") : new Date();
      if (
        exitdate != null &&
        selectedMins != "15" &&
        selectedMins != "30" &&
        selectedMins != "45" &&
        selectedMins != "00" &&
        selectedMins != null &&
        selectedMins != "" &&
        selectedHours != null &&
        selectedHours != "" &&
        selectedAMPM != null &&
        selectedAMPM != ""
      ) {
        let intervalMilliseconds = 900000;
        let datetime = new Date(
          exitdate +
          " " +
          selectedHours +
          ":" +
          selectedMins +
          " " +
          selectedAMPM
        );
        let interval = datetime.getTime() % intervalMilliseconds;
        let delta = interval === 0 ? 0 : datetime.getTime() - interval;
        delta += intervalMilliseconds;
        // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
        return new Date(delta);
      }
    },
    setRewardsDefaultDate() {
      //formatted exit date value to display
      this.rewardsTime = Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails.zone , 'timezone')
        ?
        dateToTimeZoneDate(new Date(),
          this.bookingDetails.zone.timezone)
        : new Date()
      this.minDate = this.bookingDetails.zone.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.bookingDetails.zone.timezone)
        : new Date()
      let fifteenTime = this.rewardsTime;

      this.rewardsTime =
        format(this.rewardsTime, "mm") == "15" ||
          format(this.rewardsTime, "mm") == "30" ||
          format(this.rewardsTime, "mm") == "45" ||
          format(this.rewardsTime, "mm") == "00"
          ? fifteenTime
          : this.round(this.rewardsTime)
      // this.dateTimeChange()
      // Object.hasOwnProperty.call(this.bookingDetails.booking, 'isGated') && this.bookingDetails.booking?.isGated == "1" && format(this.time, 'yyyy-MM-dd') != format(dateToTimeZoneDate(new Date(), this.bookingDetails?.zone?.timezone), 'yyyy-MM-dd') ? this.beforeDateTimeChange() : "";
    },
    async getBookingDetails() {
      this.loading = true;
      try {
        let bookingId = window.location.pathname.substring(3)
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        if (this.bookingDetails?.outStandingPaymentDetails?.status) {
          this.outStandingPayments = this.bookingDetails
            ?.outStandingPaymentDetails?.status && this.bookingDetails?.outStandingPaymentDetails?.status == 'BLOCKED' && this.bookingDetails?.zone?.showOutstandingPayment == 1
            ? true
            : false;
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    navigateToURL() {
      let url = `${process.env.VUE_APP_WEB_APP_URL}${this.outStandingBookingDetails?.booking?.bid}`;
      window.open(url, '_blank');
    },
    async removeSavedRewardCard() {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var removeCard = await bookingsvcAPIHelper(
          "POST",
          "/api/v1/removeRewardCardDetails",
          { bid: this.bookingDetails?.booking?.bid }
        );
        if (removeCard?.data?.status) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          await this.getBookingDetails()
          this.removeRewardCardDialog = true;
          this.confirmRewardsDialog = false
          this.rewardCardMsg = removeCard?.data?.message

        }
      }
      catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.confirmRewardsDialog = false

        console.log(error)

      }
    },

    async getAutoP2PDetails(bid) {
      this.loading = true;
      try {
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bid
        );
        this.$store.commit(
          "SET_OUTSTANDING_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    async copyValidationCode(data) {
      await copy.copyTextToClipboard(data);
    },
    setFreeBookingDetails() {
      const freeParkingCouponCode = this.bookingDetails?.booking
        ?.couponToRedeemFreeParking
        ? this.bookingDetails.booking.couponToRedeemFreeParking
        : "";
      const mappedZidForFreeParking = this.bookingDetails?.zone
        ?.additionalSettings?.mappedLocationForFreeParking
        ? this.bookingDetails.zone.additionalSettings
          .mappedLocationForFreeParking
        : [];
      const couponforfreeParkValidTill = this.bookingDetails?.booking
        ?.couponToRedeemFreeParkingValidTill
        ? this.bookingDetails.booking.couponToRedeemFreeParkingValidTill
        : "";
      const couponforfreeParkState = this.bookingDetails?.booking
        ?.couponToRedeemFreeParkingState
        ? this.bookingDetails.booking.couponToRedeemFreeParkingState
        : "";
      this.freeCouponValidTill =
        isValid(new Date(couponforfreeParkValidTill)) === true
          ? format(new Date(couponforfreeParkValidTill), "hh:mm a")
          : "";
      const currentTime = dateToTimeZoneDate(
        new Date(),
        this.bookingDetails?.zone?.timezone
      );
      if (
        isValid(new Date(couponforfreeParkValidTill)) === false ||
        currentTime.getTime() > new Date(couponforfreeParkValidTill).getTime()
      ) {
        this.couponExpired = true;
      }
      if (
        couponforfreeParkState === "new" && this.couponExpired === false &&
        freeParkingCouponCode !== "" &&
        freeParkingCouponCode != null &&
        mappedZidForFreeParking.length !== 0
      ) {
        localStorage.setItem(
          "freeParkingDetails",
          JSON.stringify({
            couponToRedeemFreeParking: freeParkingCouponCode,
            couponToRedeemFreeParkingValidTill: couponforfreeParkValidTill,
            mappedLocationForFreeParking: mappedZidForFreeParking,
            freeParkingBy: this.bookingDetails?.zone?.zoneName,
          })
        );
      }
    },
    getPaymentType(type) {
      switch (type) {
        case "APPLE_PAY": {
          return 'Paid with Apple Pay'
        }
        case "GOOGLE_PAY": {
          return "Paid with Google Pay"
        }
        case "Card": {
          return `${this.bookingDetails?.booking?.cardType
            ? this.bookingDetails.booking.cardType.toUpperCase()
            : ""} **** ${this.bookingDetails?.booking?.cardNo
              ? this.bookingDetails.booking.cardNo
              : ""}`
        }
      }
    },
    closeSuccessDialog() {
      this.successDialog = false;
    },
    async disassociateCard() {
      try {
        this.disassociateCardBtnLoading = true;
        let data = {
          contact: this.bookingDetails.user.contact,
          bid: this.bookingDetails.booking.bid,
          plate: this.bookingDetails.booking.vehicleNo || ''
        };
        let card = await APIHelper("POST", "/api/v1/disassociateCard", data);
        if (
          card?.data?.status === "success" &&
          card?.data?.message == "Card disassociated"
        ) {
          this.disassociate = false;
          this.cardRemoved = true;
          var bookingDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId
          );
          this.$store.commit(
            "SET_BOOKING_DETAILS",
            bookingDetails.data?.data ? bookingDetails.data.data : null
          );
          this.successMsg = "Your card has been successfully unlinked.";
          this.successDialog = true;
        } else {
          this.errMsg = card?.data?.message ? card?.data?.message : "";
          this.errDialog = true;
          this.disassociate = false;
        }
        this.disassociateCardBtnLoading = false;
      } catch (error) {
        console.log(error);
        this.disassociateCardBtnLoading = false;
        this.disassociate = false;
      }
    },
    navigateToReceipt() {
      this.$router.push({ path: "/receipt" });
    },
    formatDateTime(time) {
      let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1]; // 27
      let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
      let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
      let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
      formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
      let amOrpm = Number(hr) >= 12 ? "PM" : "AM"; //PM
      return month + " " + date + ", " + formatHr + ":" + min + " " + amOrpm; //Jan 27, 01:59 PM
    },
    checkReservationEndDate() {
      return this.bookingDetails?.booking?.revEnd &&
        new Date(
          (Number(this.bookingDetails.booking.revEnd) -
            Number(this.bookingDetails.zone.timezoneoffset) - Number(this.bookingDetails.zone.timezonedstoffset)) *
          1000
        ).getTime() -
        new Date().getTime() >
        0
        ? true
        : false;
    },
    async reEnter() {
      if (this.bookingId != null) {
        try {
          var self = this;
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId + "?reEnter=1"
          );
          this.$store.commit(
            "SET_BOOKING_DETAILS",
            bDetails.data?.data ? bDetails.data.data : null
          );
          let bookingState = bDetails.data?.data?.booking?.state;
          if (bookingState == 8 || bookingState == 10 || bookingState == 11) {
            await this.$router.replace({ path: "/checkout" });
            EventBus.$emit('SET_FREE_BOOKING')
          } else if (bookingState == 2) {
            await this.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          } else if (bookingState == 0) {
            this.$router.replace({ path: "/checkin" });
          } else {
            setTimeout(async () => {
              await self.reEnter();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    navigateToParkingSignUpPage() {
      // var self = this;
      this.postToLoggerAPI({
        phone: this.bookingDetails?.user?.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on CREATE ACCOUNT btn",
      });
      let env = this.bookingDetails?.booking?.env_overRide;
      let url = "";
      switch (env) {
        case "qa":
          url = "https://guest:guest-test@qa.parking.com/sign-up";
          break;
        case "uat":
          url = "https://uat.parking.com/sign-up";
          break;
        case "prod":
          url = "https://parking.com/sign-up";
          break;
      }
      let email = this.bookingDetails?.user?.email;
      let contact = this.bookingDetails?.user?.contact;
      // contact = contact.length > 10 ? contact.substring(contact.length -10) : contact;
      let createAccUrl =
        url +
        "?email=" +
        email +
        "&phone=" +
        contact +
        "&orderId=" +
        this.bookingDetails?.booking?.orderID;
      window.open(createAccUrl, "_blank", "", true);
    },
    // closeWindow(){
    //   // window.open(window.location, '_self').close();
    //   window.close();
    // }
  },
};
</script>
<style lang="less">
.receipt_btn {
  background-color: #f2555c !important;
  width: 100%;
  font-size: 18px !important;
  font-weight: 700 !important;
  height: 50px !important;
  letter-spacing: normal !important;
}

.thankyou_header {
  position: absolute;
  bottom: 20px;
  left: 16px;
  font-size: 24px;
  font-weight: bolder;
}

.payment_pending {
  position: absolute;
  text-align: center;
  margin: auto;
  bottom: 20px;
  font-size: 24px;
  font-weight: bolder;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.total-parking {
  color: #3d3f3c;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.0892857143em !important;
  text-transform: uppercase !important;
}

.amount-text {
  color: #3d3f3c;
  font-size: 34px;
}

.amount-content-position {
  position: relative;
}

hr {
  border: 1px solid #ffbc1f;
}

.validate-image {
  position: absolute !important;
  right: 0;
  bottom: 20px;
}

.v-input__icon--append .v-icon {
  color: purple;
}

.grey-detail-text {
  font-size: 14px;
  font-weight: 400;
}

.black-detail-text {
  font-size: 14px;
  font-weight: bold;
}

.alert-msg-box {
  background-color: #EEEEEE;
  padding: 15px 20px 10px;
  margin: 0px;
  width: 100%;
  height: min-content;
}
.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.booking_header {
  position: absolute;
  bottom: 72px;
  // top: 20px; 
  // left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.preauth_header {
  position: relative;
  margin-top: 8px;
  font-size: 18px;
  font-weight: bolder;
  z-index: 10 !important;
}

.time_ago {
  font-size: 22px;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
}

.time_ago_context {
  font-size: 16px;
  font-weight: 500;
}

.validated_time {
  font-size: 13px;
  font-weight: 500;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.booking_content {
  position: absolute !important;
  bottom: 18px !important;
  left: 16px !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.preauth_content {
  position: relative;
  font-size: 13px !important;
  font-weight: normal !important;
  z-index: 10;

}

.add_credit_card_btn {
  // border-radius: 30px;
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  letter-spacing: normal !important;
  height: 37px !important;
}

.validation-pending {
  position: absolute !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.qr_text {
  font-size: 13px;
}

.exit_dialog_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.sms-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 26px !important;
}

.exit-option-text {
  font-size: 14px;
}

.sms-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}

.qrcode-stream-wrapper video {
  max-height: 300px;
}

.counter-content-position {
  position: relative;
}

.grace-period-image {
  position: absolute !important;
  right: -51px;
  bottom: 5px;
}

.fabIcon {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}

#qr-code-full-region__dashboard {
  display: none;
}

#qr-code-full-region>video {
  width: 100% !important;
}

#validation-qr-code>video {
  width: 100% !important;
}

#qr-code-full-region img {
  display: none;
}

.no-upper-case {
  text-transform: unset !important;
}

.time-picker-input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  font-size: 16px;
  font-family: "Work Sans";
}

.time-picker-input :focus-visible {
  outline-color: #1976d2;
}

.vue__time-picker input.display-time {
  padding: 0px !important;
  border-bottom: 1px solid #767676;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #f2555c !important;
}

[data-key="am"] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key="pm"] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

// hr {
//     border: 1px solid #ffbc1f;
// }

#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}

[data-key="am"] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key="pm"] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.total-parking {
  color: #3d3f3c;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.0892857143em !important;
  text-transform: uppercase !important;
}

.amount-text {
  color: #3d3f3c;
  font-size: 34px;
}

.amount-content-position {
  position: relative;
}

.p-calendar {
  width: 100%;
}

.apply_dialog_btn {
  width: 40%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  height: 40px !important;
}

.disabled-code {
  opacity: 0.5;
  /* Adjust the opacity value for your desired disabled appearance */
  pointer-events: none;
  /* Disable pointer events on the element */
}

.alert-msg-box {
  background-color: #ececec;
  padding: 15px 20px 10px;
  margin: 0px;
  width: 100%;
  height: min-content;
}
</style>